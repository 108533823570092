<template>
  <div>
    <v-row>
      <v-col>
        <date-picker
          :value="fromDate"
          label="Datum"
          :dateFormatted="fromDateFormatted"
          @changeDate="changeFromDate"
        ></date-picker
      ></v-col>
      <v-col>
        <date-picker
          :value="toDate"
          label="t/m"
          :dateFormatted="toDateFormatted"
          @changeDate="changeToDate"
        ></date-picker>
      </v-col>
      <v-col>
        <search-client
          :searchString="searchClientString"
          :noResults="false"
          @setSearchString="setSearchClientString"
          @get-results="selectClient"
          @clear-input="clearClient"
        ></search-client>
      </v-col>
      <v-col>
        <search-item
          :searchString="searchItemString"
          @setSearchString="setSearchItemString"
          @get-results="selectItem"
          @clear-input="clearItem"
        ></search-item>
      </v-col>
      <v-col>
        <v-text-field
        label="Lotnummer"
        v-model="searchLotNumber"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex align-self-center justify-end">
        <v-btn-toggle active-class="no-active">
          <v-btn color="primary" @click="fetchItemsSalesHistory"
            >Verzenden</v-btn
          >
          <v-btn color="primary" @click="reset">Reset</v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <v-row>
      <v-card width="100%">
        <v-card-title>Artikelhistorie</v-card-title>
        <v-card-text>
          <v-data-table
            :headers="itemHeaders"
            :items="items"
            :server-items-length="itemsLength"
            :options.sync="options"
            @update:options="fetchItemsSalesHistory"
          >
            <template v-slot:[`item.date`]="{ item }">
              {{ toDateString(item.date) }}
            </template>
            <template v-slot:[`item.price`]="{ item }">
              {{ toCurrency(item.price) }}
            </template>
            <template v-slot:[`item.netSubtotal`]="{ item }">
              {{ toCurrency(item.netSubtotal) }}
            </template>
            <template v-slot:[`item.grossSubtotal`]="{ item }">
              {{ toCurrency(item.grossSubtotal) }}
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-row>
  </div>
</template>
<script>
import datePicker from "@/components/datePicker.vue"
import searchClient from "@/components/searchClient.vue"
import searchItem from "@/components/searchItem.vue"
import { fetchGET, toCurrency, toDateString } from "../js/functions"
export default {
  name: "itemsSalesHistory",
  data() {
    return {
      fromDate: new Date(Date.now()).toISOString().split("T")[0],
      toDate: new Date(Date.now()).toISOString().split("T")[0],
      fromDateFormatted: this.formatDate(
        new Date().toISOString().split("T")[0]
      ),
      toDateFormatted: this.formatDate(new Date().toISOString().split("T")[0]),
      selectedClient: {},
      selectedItem: {},
      searchClientString: "",
      searchItemString: "",
      itemHeaders: [
        { text: "Datum", value: "date" },
        { text: "Ordernr", value: "orderNumber" },
        { text: "Lotnr", value: "lotNumber" },
        { text: "Klant", value: "client" },
        { text: "Artikel", value: "item" },
        { text: "Aantal", value: "amount" },
        { text: "Eenheid", value: "unit" },
        { text: "Inhoud", value: "unitAmount" },
        { text: "Prijs", value: "price" },
        { text: "BTW", value: "VAT" },
        { text: "Subtotaal", value: "netSubtotal" },
        { text: "Totaal incl.", value: "grossSubtotal" }
      ],
      items: [],
      itemsLength: 0,
      options: {
        itemsPerPage: 50,
        page: 1,
        sortBy: ["date"],
        sortDesc: [true]
      },
      searchLotNumber: ""
    }
  },
  methods: {
    toDateString(date) {
      return toDateString(date)
    },
    toCurrency(value) {
      return toCurrency(value)
    },
    changeFromDate(date) {
      this.fromDate = date
      this.fromDateFormatted = this.formatDate(date)
      if (this.fromDate > this.toDate) {
        this.toDate = this.fromDate
        this.toDateFormatted = this.fromDateFormatted
      }
    },
    changeToDate(date) {
      this.toDate = date
      this.toDateFormatted = this.formatDate(date)
      if (this.toDate < this.fromDate) {
        this.fromDate = this.toDate
        this.fromDateFormatted = this.toDateFormatted
      }
    },
    formatDate(date) {
      return date
        .split("-")
        .reverse()
        .join("-")
    },
    async fetchItemsSalesHistory() {
      let response = await fetchGET("fetchItemsSalesHistory", {
        fromDate: this.fromDate,
        toDate: this.toDate,
        clientNumber: this.selectedClient.clientNumber,
        itemNumber: this.selectedItem.itemNumber,
        lotNumber: this.searchLotNumber,
        options: this.options
      })
      this.items = response[0].results.length ? response[0].results : []
      this.itemsLength = response[0].length.length ? response[0].length[0].length : 0
    },
    setSearchClientString(string) {
      this.searchClientString = string
    },
    selectClient(client) {
      this.selectedClient = client
    },
    clearClient() {
      this.searchClientString = ""
      this.selectedClient = {}
    },
    setSearchItemString(string) {
      this.searchItemString = string
    },
    selectItem(item) {
      this.selectedItem = item
    },
    clearItem() {
      this.searchItemString = ""
      this.selectedItem = {}
    },
    reset() {
      this.fromDate = new Date(Date.now()).toISOString().split("T")[0]
      this.toDate = new Date(Date.now()).toISOString().split("T")[0]
      this.fromDateFormatted = this.formatDate(
        new Date().toISOString().split("T")[0]
      )
      this.toDateFormatted = this.formatDate(
        new Date().toISOString().split("T")[0]
      )
      this.selectedClient = {}
      this.selectedItem = {}
      this.searchClientString = ""
      this.searchItemString = ""
      this.searchLotNumber = ""
      this.items = []
      this.itemsLength = 0
      this.options = {
        itemsPerPage: 50,
        page: 1,
        sortBy: ["date"],
        sortDesc: [true]
      }
    }
  },
  components: {
    datePicker,
    searchClient,
    searchItem
  }
}
</script>
<style scoped>
.no-active {
  color: white !important;
}
</style>